import React, {
    Fragment,
    useEffect,
    useState,
    useMemo,
    useCallback,
} from 'react';
import { observer } from 'mobx-react';
import { DropdownMenu, Spinner } from 'reactstrap';
import ErrorMessage from '../../../components/errormessage';
import ThemedSpinner from '../../../components/themed/ThemedSpinner';
import {
    ThemedDropdownItem,
    ThemedNav,
    ThemedNavItem,
    ThemedNavLink,
} from '../../../components/themed/ThemedComponents';
import Dialog from '@mui/material/Dialog';
import { useStores } from '../../../hooks/use-stores';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { STATUS } from '../../../utils/constants';
import PreviewQuestion from '../../../components/manager/assessment/preview-question';
import {
    Block,
    TextTiny,
    StatusBadge,
    Heading3,
    CopyToClipboardWrapper,
    NestedMenuItem,
    palette,
} from '@awarego/awarego-components';
import Overview from './overview';
import ThreatAreas from './threatAreas';
import Reminders from './reminders';
import Questions from './questions';
import Employees from './employees';
import pluralize from 'pluralize';
import EmployeesModal from '../../../containers/users/components/EmployeesModal';
import Modal from 'react-modal';
import ModalRename from '../../ModalRename';
import {
    Link,
    Route,
    Routes,
    useNavigate,
    useParams,
    useLocation,
} from 'react-router-dom';
import RequireRole from '../../RequireRole';
import SubHeaderText from '../../../components/header/SubHeaderText';
import SubHeaderLink from '../../../components/header/SubHeaderLink';
import SubHeaderSeparator from '../../../components/header/SubHeaderSeparator';
import HeaderSection from '../../../components/header/HeaderSection';
import SubHeaderComponentsList from '../../../components/header/SubHeaderComponentsList';
import RecommendationContainer from './recommendation/RecommendationContainer';

function AssessmentView({ companyId }) {
    const [reviewQuestionsModal, setReviewQuestionsModal] = useState(false);
    const [reviewedQuestion, setReviewedQuestion] = useState(null);
    const [isPreviewQuestion, setIsPreviewQuestion] = useState(false);
    const [renameModal, setRenameModal] = useState(false);

    const {
        assessmentStore,
        assessmentEmployeesStoresFactory,
        commonStore,
        brandingStore,
        store,
        cmi5Store,
        authStore,
    } = useStores();
    const navigate = useNavigate();
    const location = useLocation();
    const { id: assessmentId } = useParams();
    const { anonymousTracking } = authStore;

    const pathParts = location.pathname.split('/');

    //If we only have 3 parts of the path or if the final part of the path is a '', we are on the view tab
    //if (pathParts.length > 4 && pathParts[pathParts.length - 1] !== '') {
    let tab = pathParts[pathParts.length - 1];
    if (tab === 'view') {
        tab = '';
    }

    const {
        editingAssessment,
        loadingAssessment,
        loadingEmployeesToBeSentReminders,
        error,
        questionsReportList,
        latestTenAssessments,
    } = assessmentStore;
    const { assessmentManualScheduleEnabled, ssoEnabled } = store;
    const { isZIPDownloading: isPackageDownloading } = cmi5Store;

    const { employeesOverviewStore: assessmentEmployeesStore } =
        assessmentEmployeesStoresFactory;

    const bouncedEmails = useMemo(
        () => editingAssessment && editingAssessment._rawData.bouncedEmails,
        [editingAssessment]
    );

    const totalUsers = useMemo(
        () => editingAssessment && editingAssessment._rawData.usersTotal,
        [editingAssessment]
    );

    const threatAreas = useMemo(
        () => editingAssessment && editingAssessment.threatAreas,
        [editingAssessment]
    );

    useEffect(() => {
        assessmentStore.loadAssessment(companyId, assessmentId);
        assessmentStore.loadLatestTenAssessments(companyId);
    }, [companyId, assessmentId, assessmentStore]);

    /** length of the latestTenAutomations array, excluding the training currently being viewed */
    const latestTenAssessmentsCount = () => {
        return latestTenAssessments.filter(
            (a) => a.id !== parseInt(assessmentId)
        ).length;
    };

    const showBouncedModal = useCallback(() => {
        assessmentEmployeesStoresFactory.showEmployeesModal('bounced', {
            subheading: 'Emails bounced',
        });
    }, [assessmentEmployeesStoresFactory]);

    const handleDownloadCSV = () => {
        assessmentStore.downloadCSV(companyId, assessmentId);
    };

    const getDuration = () => {
        return (
            (editingAssessment.overrideDuration &&
                Math.ceil(editingAssessment.overrideDuration / 60)) ||
            (editingAssessment._rawData.duration &&
                Math.ceil(editingAssessment._rawData.duration / 60)) ||
            editingAssessment.selectedQuestionsDuration
        );
    };

    const doDelete = async () => {
        if (await assessmentStore.delete(companyId, assessmentId)) {
            navigate('/assessment');
            commonStore.success('Assessment deleted.');
        }
    };

    const onDelete = () => {
        commonStore.showConfirm(
            'Are you sure? This assessment will be deleted forever.',
            'Delete',
            'Delete Assessment',
            async () => {
                await doDelete();
            },
            true
        );
    };

    const showReviewQuestionsModal = (questionId, isPreview) => {
        setReviewedQuestion(questionId);
        setIsPreviewQuestion(isPreview);
        setReviewQuestionsModal(true);
    };

    const hideReviewQuestionsModal = () => {
        setReviewQuestionsModal(false);
        setReviewedQuestion(null);
    };

    const handleDownloadPackage = (type) => {
        cmi5Store.downloadZIP(
            companyId,
            editingAssessment.id,
            editingAssessment._rawData.uniqueId,
            type
        );
    };

    const tabs = useMemo(() => {
        if (!editingAssessment) return;
        return [
            {
                code: '',
                title: 'Overview',
                content: (
                    <Overview
                        editingAssessment={editingAssessment}
                        showReviewQuestionsModal={showReviewQuestionsModal}
                        assessmentId={assessmentId}
                        companyId={companyId}
                        hasStarted={editingAssessment.hasStarted}
                    />
                ),
            },
            {
                code: 'threatAreas',
                title: 'Threat areas',
                content: (
                    <ThreatAreas
                        threatAreas={threatAreas}
                        editingAssessment={editingAssessment}
                        assessmentId={assessmentId}
                        companyId={companyId}
                        showLists={!editingAssessment.isScorm}
                        hasStarted={editingAssessment.hasStarted}
                    />
                ),
            },
            {
                code: 'scenarios',
                title: 'Scenarios',
                content: (
                    <Questions
                        threatAreas={editingAssessment.threatAreas}
                        showReviewQuestionsModal={showReviewQuestionsModal}
                        needsFilter
                        assessmentId={assessmentId}
                        companyId={companyId}
                        hasStarted={editingAssessment.hasStarted}
                    />
                ),
            },
            {
                code: 'employees',
                title: 'Employees',
                content: (
                    <Employees
                        hasStarted={editingAssessment.hasStarted}
                        companyId={companyId}
                        showLists={!editingAssessment.isScorm}
                        assessmentId={assessmentId}
                    />
                ),
            },
            {
                code: 'reminders',
                title: 'Reminders',
                visible: !editingAssessment.isScorm,
                content: (
                    <Reminders
                        assessmentId={assessmentId}
                        companyId={companyId}
                    />
                ),
            },
            {
                code: 'recommendation',
                title: 'Recommendation',
                visible:
                    store.recommendationsEnabled &&
                    (editingAssessment.isScorm ||
                        editingAssessment.isCompleted) &&
                    store.isAdmin,
                content: (
                    <RecommendationContainer
                        assessmentId={assessmentId}
                        companyId={companyId}
                        hasStarted={editingAssessment.hasStarted}
                    />
                ),
            },
        ];
    }, [editingAssessment, assessmentId, companyId, threatAreas]);

    const sendRemindersComponent = useMemo(() => {
        if (!editingAssessment) return;
        if (!editingAssessment.canRemind) return;
        if (loadingEmployeesToBeSentReminders) return <Spinner />;
        return (
            <ThemedDropdownItem
                onClick={() =>
                    assessmentEmployeesStore.showRemindersModal(
                        false,
                        null,
                        null,
                        null,
                        tab === 'reminders'
                    )
                }
            >
                Send reminders
            </ThemedDropdownItem>
        );
    }, [
        assessmentEmployeesStore,
        editingAssessment,
        loadingEmployeesToBeSentReminders,
        tab,
    ]);

    const subHeadingContent = useMemo(() => {
        if (!editingAssessment) return;
        if (editingAssessment.isScorm)
            return (
                <Fragment>
                    <StatusBadge
                        color={editingAssessment.statusInfo.color}
                        text={editingAssessment.statusInfo.label}
                    />
                    <SubHeaderText
                        text={
                            'Created ' +
                            moment(editingAssessment.date).format('ll[,] LT')
                        }
                    />
                    {editingAssessment._rawData.usersFinished > 0 && (
                        <Fragment>
                            <SubHeaderText
                                text={
                                    'Last Completion ' +
                                    moment(
                                        editingAssessment._rawData.lastCompleted
                                    ).format('ll[,] LT')
                                }
                                separator
                            />
                        </Fragment>
                    )}
                </Fragment>
            );
        return (
            <Fragment>
                <StatusBadge
                    color={editingAssessment.statusInfo.color}
                    text={editingAssessment.statusInfo.label}
                />
                <SubHeaderText
                    text={
                        'Duration: ' +
                        getDuration() +
                        ' ' +
                        pluralize('minute', getDuration())
                    }
                />
                <SubHeaderText
                    text={
                        totalUsers +
                        ' ' +
                        pluralize('employee', totalUsers) +
                        ' enrolled'
                    }
                    separator
                />
                {bouncedEmails > 0 && (
                    <SubHeaderLink
                        text={
                            bouncedEmails +
                            ' ' +
                            pluralize('email', bouncedEmails) +
                            ' bounced'
                        }
                        color={palette.vibrant.red}
                        onClick={showBouncedModal}
                    />
                )}
                {editingAssessment.scheduleType !== -1 && (
                    <>
                        {/*<SubHeaderSeparator />*/}
                        <SubHeaderText
                            text={
                                'Delivery ' +
                                (editingAssessment.deliveryDate
                                    ? moment(
                                          editingAssessment.deliveryDate
                                      ).format('ll[,] LT')
                                    : 'not set')
                            }
                            separator
                        />
                    </>
                )}
                <SubHeaderText
                    text={
                        'Due ' +
                        (editingAssessment.due
                            ? moment(editingAssessment.due).format('ll[,] LT')
                            : 'not set')
                    }
                    separator
                />
                {assessmentManualScheduleEnabled &&
                    (editingAssessment.status === STATUS.SCHEDULED ||
                        editingAssessment.status === STATUS.ACTIVE) && (
                        <>
                            <SubHeaderSeparator />
                            <CopyToClipboardWrapper
                                copyText={`${
                                    brandingStore.myDomain
                                }/assessments/${assessmentId}?o=${companyId}${
                                    ssoEnabled ? '&sso=1' : ''
                                }`}
                                onCopied={commonStore.copied}
                            >
                                <TextTiny lighter>Copy link</TextTiny>
                            </CopyToClipboardWrapper>
                        </>
                    )}
            </Fragment>
        );
    }, [
        assessmentId,
        assessmentManualScheduleEnabled,
        bouncedEmails,
        brandingStore.myDomain,
        companyId,
        editingAssessment,
        showBouncedModal,
        ssoEnabled,
        totalUsers,
    ]);

    const createContextMenu = useMemo(() => {
        if (!editingAssessment) return;
        return (
            <DropdownMenu persist={true}>
                <RequireRole>
                    {sendRemindersComponent}
                    {editingAssessment.status !== STATUS.COMPLETED &&
                        !editingAssessment.isScorm && (
                            <ThemedDropdownItem>
                                <Link
                                    to={`/assessment/edit/${editingAssessment.id}`}
                                >
                                    Edit assessment
                                </Link>
                            </ThemedDropdownItem>
                        )}
                </RequireRole>
                {!anonymousTracking && (
                    <ThemedDropdownItem onClick={handleDownloadCSV}>
                        Detailed report (.csv)
                    </ThemedDropdownItem>
                )}
                {editingAssessment.isScorm && (
                    <NestedMenuItem
                        text="Download assessment"
                        loading={isPackageDownloading}
                        items={[
                            {
                                text: 'SCORM 1.2',
                                props: {
                                    onClick: () => {
                                        handleDownloadPackage('scorm12');
                                    },
                                },
                            },
                            {
                                text: 'SCORM 2004 3rd Edition',
                                props: {
                                    onClick: () => {
                                        handleDownloadPackage('scorm2004');
                                    },
                                },
                            },
                            {
                                text: 'cmi5',
                                props: {
                                    onClick: () => {
                                        handleDownloadPackage('cmi5');
                                    },
                                },
                            },
                        ]}
                    />
                )}
                <RequireRole>
                    <ThemedDropdownItem onClick={() => setRenameModal(true)}>
                        Rename assessment
                    </ThemedDropdownItem>
                    <ThemedDropdownItem onClick={onDelete}>
                        <TextTiny bold color={palette.vibrant.raspberry}>
                            Delete assessment
                        </TextTiny>
                    </ThemedDropdownItem>
                </RequireRole>
            </DropdownMenu>
        );
    }, [
        editingAssessment,
        handleDownloadCSV,
        onDelete,
        sendRemindersComponent,
    ]);

    const createHeadingDropdown = useMemo(() => {
        if (!editingAssessment) return;
        return (
            <DropdownMenu>
                {assessmentStore.loadingAssessments ? (
                    <ThemedSpinner />
                ) : (
                    latestTenAssessments.map((x, i) => {
                        if (x.id !== editingAssessment.id)
                            return (
                                <ThemedDropdownItem
                                    key={i}
                                    as={Link}
                                    to={x.link}
                                >
                                    <b>{x.name}</b>
                                </ThemedDropdownItem>
                            );
                    })
                )}
            </DropdownMenu>
        );
    }, [editingAssessment, latestTenAssessments]);

    return (
        <Fragment>
            <Link to={`/assessment`}>
                <div className={'navigation back-button'}>
                    Back to all assessments
                </div>
            </Link>
            <ErrorMessage error={error} />
            {loadingAssessment ? (
                <ThemedSpinner />
            ) : editingAssessment ? (
                <Fragment>
                    {/* top dashboard */}
                    <HeaderSection
                        heading={editingAssessment.name}
                        headingDropdown={createHeadingDropdown}
                        headingDropdownContentCount={latestTenAssessmentsCount()}
                        subHeadings={
                            <SubHeaderComponentsList>
                                {subHeadingContent}
                            </SubHeaderComponentsList>
                        }
                        contextMenu={createContextMenu}
                    />

                    {/* tabs */}
                    <Block resetPadding py={0} px={24}>
                        <ThemedNav>
                            {tabs.map(
                                (t) =>
                                    t.visible !== false && (
                                        <ThemedNavItem
                                            active={tab === t.code}
                                            key={t.code}
                                            strongerMargin
                                        >
                                            <ThemedNavLink
                                                to={t.code}
                                                as={Link}
                                                $mildPadding
                                            >
                                                {t.title}
                                            </ThemedNavLink>
                                        </ThemedNavItem>
                                    )
                            )}
                        </ThemedNav>
                    </Block>

                    {/* tab content */}

                    <Routes>
                        <Route path={'/'} element={tabs[0].content} />
                        {tabs
                            .slice()
                            .reverse()
                            .map((t) => (
                                <Route path={t.code} element={t.content} />
                            ))}
                    </Routes>

                    <Modal
                        isOpen={renameModal}
                        onRequestClose={() => setRenameModal(false)}
                        className="Modal"
                    >
                        <ModalRename
                            onRequestClose={() => setRenameModal(false)}
                            item={editingAssessment}
                            itemStore={assessmentStore}
                            companyId={companyId}
                        />
                    </Modal>
                    {/* employees modal */}
                    <EmployeesModal
                        companyId={companyId}
                        productId={assessmentId}
                        productEmployeesStoresFactory={
                            assessmentEmployeesStoresFactory
                        }
                        product={assessmentStore.editingAssessment}
                    />

                    {/* question preview */}
                    <Dialog
                        fullScreen
                        open={reviewQuestionsModal}
                        onClose={hideReviewQuestionsModal}
                        className="review-questions-modal"
                    >
                        <PreviewQuestion
                            questionId={reviewedQuestion}
                            onRequestClose={hideReviewQuestionsModal}
                            type={!isPreviewQuestion && 'questionReview'}
                            companyId={companyId}
                            hasMoreQuestions={questionsReportList.length > 1}
                            assessmentId={editingAssessment.id}
                            questionsSelected={editingAssessment.questions}
                            questionsReportList={questionsReportList}
                            isScorm={editingAssessment.isScorm}
                        />
                    </Dialog>
                </Fragment>
            ) : (
                <Row>
                    <Col xs="12">
                        <div className="block no-assessments-found">
                            <img
                                src="/static/img/icon-booklist.svg"
                                alt="Assessment not found"
                            />
                            <Heading3 displayBlock mt={16} mb={8}>
                                Assessment not found :(
                            </Heading3>
                        </div>
                    </Col>
                </Row>
            )}
        </Fragment>
    );
}

export default observer(AssessmentView);

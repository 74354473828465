import React, { Fragment, useMemo } from 'react';
import { Tooltip } from '@mui/material';
import { defaultZero, calculateColor } from '../../../utils/helpers';
import Summary from './summary';
import {
    Heading2,
    Heading3,
    FlexDiv,
    TextDefault,
    Block,
    StatusBadge,
    SpaceBetweenDiv,
    ThemedButton,
    ProgressWithNumber,
    TextWithTooltipIcon,
    DropdownThemedButton,
    Tag,
    palette,
} from '@awarego/awarego-components';
import { Link } from 'react-router-dom';
import NoItemsYet from '../no-items-yet';
import { STATUS, LINKS } from '../../../utils/constants';
import TrainingUpgrade from '../automation/TrainingUpgrade';
import FullWidthLink from '../../FullWidthLink';
import RequireRole from '../../../containers/RequireRole';
import TableClient from '../../../components/table/TableClient';
import StyledLink from '../../../components/StyledLink';

function AutomationDashboard({
    dashboardData,
    trainings,
    trainingProgramsUnavailable,
    upgradeAllowed,
    supportEmail,
    demoLink,
    cmi5enabled,
}) {
    const columns = useMemo(
        () => [
            {
                Header: 'Training program',
                accessor: 'name',
                Cell: (x) => (
                    <>
                        <StyledLink
                            to={`/trainings/${x.row.original.id}/view`}
                            $hoverColor={palette.graphite.onyx}
                            $underlined={true}
                        >
                            {x.value && x.value.length > 0 ? (
                                x.value.length > 35 ? (
                                    <Tooltip title={x.value}>
                                        <span>
                                            <TextDefault bold>
                                                {x.value.substring(0, 35)}
                                                ...
                                            </TextDefault>
                                        </span>
                                    </Tooltip>
                                ) : (
                                    <TextDefault bold>{x.value}</TextDefault>
                                )
                            ) : (
                                <Tag text="No Name" />
                            )}{' '}
                        </StyledLink>
                        <StatusBadge
                            color={x.row.original.statusInfo.color}
                            text={
                                <Fragment>
                                    {x.row.original.statusInfo.label}{' '}
                                    {x.row.original.status === STATUS.ACTIVE &&
                                    !x.row.original.isScorm
                                        ? `(${x.row.original._rawData.subjectsSent}/${x.row.original._rawData.totalSubjects} delivered)`
                                        : ''}
                                </Fragment>
                            }
                        />
                    </>
                ),
                disableSortBy: true,
            },
            {
                Header: '# Employees',
                accessor: '_rawData.totalUsers',
                Cell: (x) => {
                    return <TextDefault>{x.value}</TextDefault>;
                },
                disableSortBy: true,
            },
            {
                Header: 'Attendance',
                accessor: 'attendance',
                Cell: (x) => {
                    return (
                        <TextDefault bold color={calculateColor(x.value)}>
                            {defaultZero(x.value)}%
                        </TextDefault>
                    );
                },
                tooltip:
                    'Percentage of employees who have started to watch at least one lesson in a training.',
                disableSortBy: true,
            },
            {
                Header: 'Completion rate',
                accessor: 'awarenessScore',
                Cell: (x) => <ProgressWithNumber value={x.value} height={6} />,
                tooltip: 'Employees that have completed all training lessons.',
                disableSortBy: true,
            },
        ],
        []
    );

    if (trainingProgramsUnavailable) {
        return (
            <RequireRole>
                <TrainingUpgrade
                    upgradeAllowed={upgradeAllowed}
                    supportEmail={supportEmail}
                    demoLink={demoLink}
                />
            </RequireRole>
        );
    }

    return trainings.length > 0 ? (
        <Block tablePadding>
            <Heading2 mb={16} ml={16}>
                Training programs
            </Heading2>
            <FlexDiv justifyCenter alignCenter $noWrap>
                <Summary
                    mainNumberText={'Active'}
                    mainNumber={dashboardData.active.value}
                    secondaryNumberText={
                        <TextWithTooltipIcon
                            tooltipText="Employees who have not completed all assigned available training. "
                            textComponent="Currently enrolled"
                        />
                    }
                    secondaryNumber={dashboardData.active.users}
                    iconUrl={'/static/img/icon-dashboard-repeat.svg'}
                    iconAlt={'arrow in circle'}
                    item="trainings"
                />
                <Summary
                    mainNumberText={'Completed'}
                    mainNumber={dashboardData.completed.value}
                    secondaryNumberText={'Completed training'}
                    secondaryNumber={dashboardData.completed.users}
                    iconUrl={'/static/img/icon-dashboard-completed.svg'}
                    iconAlt={'circle with tick inside'}
                    item="trainings"
                />
            </FlexDiv>
            <Heading3 my={24} ml={16}>
                Training performance
            </Heading3>
            <Block noBorder resetPadding resetMargin>
                <TableClient
                    columns={columns}
                    data={trainings}
                    hidePagination
                    link={(row) =>
                        row.original.isScorm ? null : LINKS.training
                    }
                />
            </Block>
            <FullWidthLink to={`/trainings`}>
                <ThemedButton
                    mode="secondary"
                    size="fullWidth"
                    text="All training programs"
                />
            </FullWidthLink>
        </Block>
    ) : (
        <NoItemsYet
            img={'/static/img/training-empty.svg'}
            imgAlt={'sneak peak into a training program'}
            title={'No training programs yet'}
            text={
                'Build security culture and keep security awareness high with our behavior-changing training programs.'
            }
            heading="Training programs"
            buttons={
                <RequireRole>
                    <SpaceBetweenDiv gap={24}>
                        {cmi5enabled ? (
                            <DropdownThemedButton
                                text="Create training"
                                wrapperProps={{ flexGrow: 1 }}
                                btnProps={{ mode: 'primary' }}
                                items={[
                                    {
                                        text: 'Training',
                                        props: {
                                            component: Link,
                                            to: '/trainings/create',
                                        },
                                    },
                                    {
                                        text: 'Training (SCORM, cmi5)',
                                        props: {
                                            component: Link,
                                            to: '/trainings/create_scorm',
                                        },
                                    },
                                ]}
                            />
                        ) : (
                            <ThemedButton
                                mode="primary"
                                as={Link}
                                flexGrow={1}
                                $center
                                to={`/trainings/create`}
                                text="Create new"
                            />
                        )}
                        <ThemedButton
                            as="a"
                            href={demoLink}
                            target="_blank"
                            rel="noreferrer"
                            mode="secondary"
                            text="Try demo"
                            flexBasisPercent={cmi5enabled ? 50 : undefined}
                            flexGrow={!cmi5enabled ? 1 : undefined}
                            $center
                        />
                    </SpaceBetweenDiv>
                </RequireRole>
            }
        />
    );
}

export default AutomationDashboard;
